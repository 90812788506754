import { ISchedule } from "../../interfaces";

export const gbVersionSchedules: ISchedule[] = [
  {
    label: "Global 2022-12-09",
    url: "https://twitter.com/g_destiny_child/status/1601122418940350467",
  },
  {
    label: "Global 2022-08-19",
    url: "https://twitter.com/g_destiny_child/status/1560540568752889856",
  },
  {
    label: "Global 2022-07-22",
    url: "https://twitter.com/g_destiny_child/status/1550376806427275264",
  },
  {
    label: "Global 2022-06-27",
    url: "https://twitter.com/g_destiny_child/status/1541346846656036864",
  },
  {
    label: "Global 2022-05-27",
    url: "https://twitter.com/g_destiny_child/status/1530063153513779201",
  },
  {
    label: "Global 2022-04-28",
    url: "https://twitter.com/g_destiny_child/status/1519632621042831362",
  },
  {
    label: "Global 2022-02",
    url: "https://twitter.com/g_destiny_child/status/1497131399085985795",
  },
  {
    label: "Global 2022-01",
    url: "https://twitter.com/g_destiny_child/status/1486616970237997057",
  },
  {
    label: "Global 2021-12",
    url: "https://twitter.com/g_destiny_child/status/1474256757547737088",
  },
  {
    label: "Global 2021-11",
    url: "https://twitter.com/g_destiny_child/status/1463752630140751874",
  },
  {
    label: "Global 2021-10",
    url: "https://twitter.com/g_destiny_child/status/1452591200171487239",
  },
  {
    label: "Global 2021-09",
    url: "https://twitter.com/g_destiny_child/status/1441292842131804161",
  },
  {
    label: "Global 2021-08",
    url: "https://moot.us/lounges/73/boards/280/posts/5415593/notice-lisa-s-upcoming-schedule-calendar",
  },
  {
    label: "Global 2021-07",
    url: "https://moot.us/lounges/73/boards/280/posts/5409714/notice-lisa-s-upcoming-schedule-calendar",
  },
];
