import { ISchedule } from "../../interfaces";

export const previews = [
  {
    patchDate: "2022-09-15",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2384779",
  },
  {
    patchDate: "2022-08-18",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2382453",
  },
];

export const krVersionSchedules: ISchedule[] = [
  {
    label: "KR 2023-01-05",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2396444",
    img: "20230105_캘린더.png",
    coords: [
      {
        top: 133,
        left: 87,
        date: "2023-01-05",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2396355",
      },
      {
        top: 320,
        left: 218,
        date: "2023-01-19",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2396359",
      },
      {
        top: 507,
        left: 87,
        date: "2023-02-02",
      },
      {
        top: 694,
        left: 218,
        date: "2023-02-16",
      },
    ],
  },
  {
    label: "KR 2022-12-08",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2394252",
    img: "20221208_캘린더.png",
    coords: [
      {
        top: 133,
        left: 87,
        date: "2022-12-08",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2394147",
      },
      {
        top: 320,
        left: 218,
        date: "2022-12-22",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2394150",
      },
      {
        top: 507,
        left: 87,
        date: "2023-01-05",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2396355",
      },
      {
        top: 694,
        left: 218,
        date: "2023-01-19",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2396359",
      },
    ],
  },
  {
    label: "KR 2022-11-10",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2392176",
    img: "221110_캘린더.png",
    coords: [
      {
        top: 133,
        left: 87,
        date: "2022-11-10",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2392025",
      },
      {
        top: 320,
        left: 218,
        date: "2022-11-24",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2392029",
      },
      {
        top: 507,
        left: 87,
        date: "2022-12-08",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2394147",
      },
      {
        top: 694,
        left: 218,
        date: "2022-12-22",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2394150",
      },
    ],
  },
  {
    label: "KR 2022-10-13",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2388258",
    img: "221013_캘린더.png",
    coords: [
      {
        top: 319,
        left: 217,
        date: "2022-10-27",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2388087",
      },
      {
        top: 694,
        left: 219,
        date: "2022-11-24",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2392029",
      },
    ],
  },
  {
    label: "KR 2022-09-15",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2385564",
    img: "220915_캘린더.png",
    coords: [
      {
        top: 133,
        left: 86,
        date: "2022-09-15",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2385423",
      },
      {
        top: 319,
        left: 217,
        date: "2022-09-29",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2385426",
      },
      {
        top: 694,
        left: 217,
        date: "2022-10-27",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2388087",
      },
    ],
  },
  {
    label: "KR 2022-08-18",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2382937",
    img: "220818_캘린더.png",
    coords: [
      {
        top: 133,
        left: 86,
        date: "2022-08-18",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2382820",
      },
      {
        top: 319,
        left: 217,
        date: "2022-09-01",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2382823",
      },
      {
        top: 507,
        left: 88,
        date: "2022-09-15",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2385423",
      },
      {
        top: 694,
        left: 217,
        width: 60,
        date: "2022-09-29",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2385426",
      },
    ],
  },
  {
    label: "KR 2022-07-21",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2380095",
    img: "220721_캘린더_kr.png",
    coords: [
      {
        top: 132,
        left: 89,
        date: "2022-07-21",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2379991",
      },
      {
        top: 319,
        left: 217,
        date: "2022-08-04",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2379994",
      },
      {
        top: 505,
        left: 89,
        date: "2022-08-18",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2382820",
      },
    ],
  },
  {
    label: "KR 2022-06-23",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2376705",
    img: "220623_캘린더_kr.png",
    coords: [
      {
        top: 505,
        left: 89,
        date: "2022-07-21",
        href: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2379991",
      },
    ],
  },
  {
    label: "KR 2022-05-26",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2374402",
  },
  {
    label: "KR 2022-04-28",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2372418",
  },
  {
    label: "KR 2022-03",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2370381",
  },
  {
    label: "KR 2022-02",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2368048",
  },
  {
    label: "KR 2022-01",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2365195",
  },
  {
    label: "KR 2021-12",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2361552",
  },
  {
    label: "KR 2021-11",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2357533",
  },
  {
    label: "KR 2021-10",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2351346",
  },
  {
    label: "KR 2021-09",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2343203",
  },
  {
    label: "KR 2021-08",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2338484",
  },
  {
    label: "KR 2021-07",
    url: "https://cafe.naver.com/ArticleRead.nhn?clubid=27917479&articleid=2334414",
  },
];
