import * as React from "react";
import TwitterLogo from "../../components/TwitterLogo";
import { ISchedule } from "../../utils/interfaces";
import ComingSoonPic from "./ComingSoonPic";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import styled from "styled-components";

interface IProps {
  label: string;
  twitterUrl: string;
  elements?: ISchedule[];
}

const Link = styled.a`
  width: 140px;
  text-decoration: none;
`;

export default function VersionBlock(props: IProps) {
  const [currentScheduleIndex, setCurrentScheduleIndex] = React.useState(-1);

  React.useEffect(() => {
    if (props.elements === undefined) return;
    if (props.elements[0].img === undefined) return;
    setCurrentScheduleIndex(0);
  }, [props.elements]);

  const handleScheduleClick = (e: any, scheduleIndex: number) => {
    if (props.elements === undefined) return;
    if (props.elements[scheduleIndex].img === undefined) return;

    e.preventDefault();
    setCurrentScheduleIndex(scheduleIndex);
  };

  return (
    <>
      {/* header */}
      <div style={{ display: "flex" }}>
        <div style={{ width: "120px" }}>{props.label}</div>
        <a href={props.twitterUrl}>
          <TwitterLogo />
        </a>
      </div>
      {/* schedule menu */}
      <div style={{ display: "flex" }}>
        {props.elements !== undefined && props.elements.length > 0 && (
          <ul>
            {props.elements.map((schedule, index) => (
              <li key={index}>
                <div style={{ display: "flex" }}>
                  <Link
                    style={{
                      fontWeight: currentScheduleIndex === index ? "bold" : "unset",
                    }}
                    href={schedule.url}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => handleScheduleClick(e, index)}
                  >
                    {schedule.label}
                  </Link>
                  {schedule.img && <CalendarIcon style={{ justifyItems: "right" }} />}
                </div>
              </li>
            ))}
          </ul>
        )}

        {/* coming soon image */}
        {props.elements !== undefined &&
          currentScheduleIndex >= 0 &&
          props.elements[currentScheduleIndex].img !== undefined && (
            <ComingSoonPic
              imgUrl={`img/schedules/${props.elements[currentScheduleIndex].img}`}
              coords={props.elements[currentScheduleIndex].coords}
            />
          )}
      </div>
    </>
  );
}
