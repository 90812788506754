export const miscInfo = [
  { url: "https://live2d-dcg.github.io" },
  { url: "http://riceminegl.mnyiaa.com" },
  {
    label: "DCG Database google doc (by ToxicWayz)",
    url: "https://docs.google.com/spreadsheets/u/2/d/e/2PACX-1vQtAzEGZTUDqev509gxzuIbP9PJnu-JLjvm8xUfY1WIuGcHlIIH3bvy4LOaJrQCjd80AFDCxQN1-1KC/pubhtml#",
  },
  {
    label: "Fandom",
    url: "https://destiny-child-for-kakao.fandom.com/wiki/Destiny_Child_for_Kakao_Wiki",
  },
  { label: "Moot", url: "https://moot.us/lounges/73/" },
  { label: "Naver", url: "https://cafe.naver.com/destinychild/" },
  {
    label: "Equipment options guide",
    url: "https://docs.google.com/document/d/1IfhbKap_0Rsb0qzWqNgd3UHfZvjiJQybBGaeXAlUUnc/edit",
  },
  {
    label: "Destiny child (kr) on QooApp",
    url: "https://apps.qoo-app.com/en/app/4387",
  },
];
