import React from "react";
import { gbVersionSchedules, krVersionSchedules, miscInfo } from "../../utils/data";
import VersionBlock from "./VersionBlock";

/*
Stats to prioritize by class:
Attacker - ATK, AGL/CRT
Defender - HP, DEF
Debuffer - HP, DEF, AGL
Supporter - HP, DEF, AGL
Healer - ATK, HP, CRT
*/

export default function Info() {
  return (
    <div>
      <div>
        <div style={{ textDecoration: "underline" }}>Schedules</div>
        <VersionBlock
          label="Korean version"
          twitterUrl="https://twitter.com/DestinyChild_kr"
          elements={krVersionSchedules.filter((s) => !!s.label && !!s.url)}
        />
        <VersionBlock
          label="Global version"
          twitterUrl="https://twitter.com/g_destiny_child"
          elements={gbVersionSchedules}
        />
        <VersionBlock label="Japan version" twitterUrl="https://twitter.com/destinyc_info" />
      </div>
      <hr />
      {/* <div>
                <img src="img/ignition.png" alt="ignition info" />
            </div> */}
      <div>
        More info at
        <div>
          <ul>
            {miscInfo.map((s, index) => (
              <li key={index}>
                <a href={s.url}>{s.label ?? s.url}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
