const prod = {
    AUTH_TOKEN_AUDIENCE: 'https://gamate.eu.auth0.com/api/v2/',
    AUTH_DOMAIN: 'gamate.eu.auth0.com',
    AUTH_CLIENTID: '70wu2D12fLGCm3MNoJfg4kVeHRHbCecL',
    AUTH_SCOPE: 'read:current_user read:users update:current_user_metadata',
}

const dev = {
    AUTH_TOKEN_AUDIENCE: 'https://cherubia.auth0.com/api/v2/',
    AUTH_DOMAIN: 'cherubia.auth0.com',
    AUTH_CLIENTID: 'JkQBOS6dMCvl1opZkXOIw0qYEtAE4zHb',
    AUTH_SCOPE: 'read:current_user read:users update:current_user_metadata',
}

export const config = process.env.NODE_ENV !== 'production' ? dev : prod