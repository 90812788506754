import React from "react";
import { Switch, Route } from "react-router-dom";

import { Header } from "@gamate/components";

import Footer from "./components/Footer";
import Info from "./pages/Info";
import Items from "./pages/Items";
// import PartyBuilder from "./pages/PartyBuilder";

import "./App.css";

const routes = [
  // { path: "/party", label: "party builder", component: PartyBuilder },
  { path: "/", label: "items", component: Items },
  { path: "/info", label: "info", component: Info },
];

const routesToMenu = () => {
  return new Map(
    routes.map((route) => {
      return [route.path, route.label];
    })
  );
};

export default function App() {
  const routeComponents = routes.map((route, index) => (
    <Route key={index} path={route.path} exact component={route.component} />
  ));
  return (
    <>
      <Header
        menu={routesToMenu()}
        // title="Gamate | Destiny Child"
      />
      <div>
        <Switch>{routeComponents}</Switch>
      </div>
      <Footer />
    </>
  );
}
