import * as React from "react";
import styled from "styled-components";
import { IUpdateNoticeCoord } from "../../../utils/interfaces";

import "./ComingSoonPic.css";

interface IProps {
  imgUrl: string;
  coords?: IUpdateNoticeCoord[];
  label?: string;
}

const ImgContainer = styled.div``;
const ScheduleImg = styled.img`
  width: var(--schedule-width);
  opacity: 0.7;
`;

const Container = styled.div`
  position: relative;
  margin-left: 20px;
`;

const BlinkBlock = styled.a<any>`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  width: ${(props) => (props.width ? `${props.width}px` : "var(--block-width)")};
  height: var(--block-height);
  ${ImgContainer}:hover & {
    border: solid 1px;
    border-radius: 5px;
    background-color: var(--blink-bgcolor);
    opacity: var(--blink-opacity-max);
    animation: blink-animation 0.5s 1;
  }
`;

const BrightBlock = styled.div<any>`
  background-image: url("${(props) => props.src}");
  background-size: var(--schedule-width);
  background-repeat: no-repeat;
  background-position: top ${(props) => `-${props.top}px`} left ${(props) => `-${props.left}px`};
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  width: ${(props) => (props.width ? `${props.width}px` : "var(--block-width)")};
  height: var(--block-height);
  opacity: 0;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export default function ComingSoonPic(props: IProps) {
  // BrightBlocks not contained in ImgContainer to make sure it is above it for bright effect
  return (
    <Container>
      <ImgContainer>
        <ScheduleImg src={props.imgUrl} />
        {props.coords !== undefined &&
          props.coords.length > 0 &&
          props.coords
            .filter((coord) => coord.href)
            .map((coord, index) => (
              <BlinkBlock
                key={index}
                top={coord.top}
                left={coord.left}
                href={coord.href}
                width={coord.width}
              />
            ))}
      </ImgContainer>
      {props.coords !== undefined &&
        props.coords.length > 0 &&
        props.coords
          .filter((coord) => coord.href)
          .map((coord, index) => (
            <a key={index} href={coord.href} target="_blank" rel="noreferrer">
              <BrightBlock
                src={props.imgUrl}
                top={coord.top}
                left={coord.left}
                width={coord.width}
              />
            </a>
          ))}
    </Container>
  );
}
