import { IItem } from "../interfaces";

export const weapons: IItem[] = [
  {
    name: "Sacred chapel",
    img: "img/items/weapons/sacredchapel.png",
    stats: {
      atk: 865,
      def: 245,
      version: { name: "kr", number: "2.9.3" },
    },
  },
  {
    name: "Rusalka's wings",
    img: "img/items/weapons/rusalkaswings.png",
    stats: {
      atk: 798,
      cri: 798,
      version: { name: "kr", number: "2.9.3" },
    },
  },
  {
    name: "메탈 허러벨트",
    img: "img/items/weapons/메탈 허러벨트.png",
    stats: {
      atk: 1197,
      agi: 399,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "로술렌타 소드",
    img: "img/items/weapons/로술렌타 소드.png",
    stats: {
      atk: 1140,
      agi: 456,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Jegi new year's toy",
    img: "img/items/weapons/jeginewyearstoy.png",
    stats: {
      atk: 1035,
      agi: 460,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "유니버스 완드",
    img: "img/items/weapons/유니버스 완드.png",
    stats: {
      atk: 900,
      cri: 696,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "넵튠의 마이크",
    img: "img/items/weapons/넵튠의 마이크.png",
    stats: {
      atk: 1224,
      cri: 372,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "푸른 날의 세이버",
    img: "img/items/weapons/푸른 날의 세이버.png",
    stats: {
      atk: 798,
      agi: 798,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Cain's crosier",
    img: "img/items/weapons/cainscrosier.png",
    stats: {
      atk: 1209,
      cri: 381,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Laima's rapier",
    img: "img/items/weapons/laimasrapier.png",
    stats: {
      atk: 1124,
      cri: 469,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Hyenang's scissors",
    img: "img/items/weapons/hyenangsscissors.png",
    stats: {
      atk: 1212,
      agi: 381,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "Nirrti's karma",
    img: "img/items/weapons/nirrtiskarma.png",
    stats: {
      atk: 912,
      agi: 682,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "Mint chocolate balloon",
    img: "img/items/weapons/mintchocolateballoon.png",
    stats: {
      atk: 1004,
      cri: 592,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "자유의검",
    img: "img/items/weapons/자유의검.png",
    stats: {
      atk: 1221,
      agi: 375,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "샤인악스",
    img: "img/items/weapons/샤인악스.png",
    stats: {
      atk: 1221,
      cri: 375,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "1st anniversary popper",
    img: "img/items/weapons/1stanniversarypopper.png",
    stats: {
      hp: 456,
      atk: 947,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Artemisia's Gloves",
    img: "img/items/weapons/artemisiasgloves.png",
    stats: {
      atk: 998,
      cri: 598,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Axe",
    img: "img/items/weapons/axe.jpg",
    stats: {
      atk: 820,
      cri: 213,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Boreas shoes",
    img: "img/items/weapons/boreasshoes.png",
    stats: {
      atk: 1219,
      cri: 377,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Charlemagne's sword",
    img: "img/items/weapons/charlemagnessword.png",
    stats: {
      atk: 798,
      cri: 798,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Count's sword",
    img: "img/items/weapons/countssword.png",
    stats: {
      atk: 555,
      hp: 555,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Cross sword",
    img: "img/items/weapons/crosssword.png",
    stats: {
      atk: 1226,
      agi: 379,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Crush mallet",
    img: "img/items/weapons/crushmallet.png",
    stats: {
      atk: 900,
      agi: 696,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Dana's baculus",
    img: "img/items/weapons/danasbaculus.png",
    stats: {
      atk: 1080,
      cri: 462,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Demeter syringe",
    img: "img/items/weapons/demetersyringe.png",
    stats: {
      atk: 995,
      cri: 426,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Dizzy high heels",
    img: "img/items/weapons/dizzyhighheels.png",
    stats: {
      atk: 860,
      agi: 736,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Echu's ski",
    img: "img/items/weapons/echusski.png",
    stats: {
      atk: 1215,
      agi: 381,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Electric chainsaw",
    img: "img/items/weapons/electricchainsaw.png",
    stats: {
      atk: 245,
      cri: 865,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Evil flower syringe",
    img: "img/items/weapons/evilflower.jpg",
    stats: {
      atk: 1212,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Exorcist's sword",
    img: "img/items/weapons/exorcistssword.png",
    stats: {
      atk: 1036,
      agi: 557,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Fake scimitar",
    img: "img/items/weapons/fakescimitar.jpg",
    stats: {
      atk: 865,
      cri: 245,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Fire spear",
    img: "img/items/weapons/firespear.png",
    stats: {
      atk: 1060,
      agi: 536,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Gothic parasol",
    img: "img/items/weapons/gothicparasol.jpg",
    stats: {
      atk: 1058,
      cri: 453,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Halberd",
    img: "img/items/weapons/halberd.jpg",
    stats: {
      atk: 865,
      agi: 245,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Harpy's gloves",
    img: "img/items/weapons/harpysgloves.png",
    stats: {
      atk: 1020,
      agi: 576,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Hatchet",
    img: "img/items/weapons/hatchet.png",
    stats: {
      atk: 820,
      agi: 213,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Heart gun",
    img: "img/items/weapons/heartgun.png",
    stats: {
      atk: 555,
      cri: 555,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Kush's tail",
    img: "img/items/weapons/kushstail.png",
    stats: {
      atk: 1197,
      cri: 399,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Limo's trident",
    img: "img/items/weapons/limostrident.png",
    stats: {
      atk: 1113,
      agi: 477,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Lovely bell",
    img: "img/items/weapons/lovelybell.png",
    stats: {
      atk: 820,
      cri: 776,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Mona's debt",
    img: "img/items/weapons/monasdebt.png",
    stats: {
      atk: 1140,
      cri: 456,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Nunchuk",
    img: "img/items/weapons/nunchuk.png",
    stats: {
      atk: 865,
      hp: 245,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Old general's power knuckle",
    img: "img/items/weapons/oldgeneralspowerknuckle.png",
    stats: {
      atk: 1118,
      agi: 424,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Palm trees of Amos",
    img: "img/items/weapons/palmtreesofamos.png",
    stats: {
      atk: 912,
      cri: 684,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Philote's Umbrella",
    img: "img/items/weapons/philotesumbrella.png",
    stats: {
      atk: 1226,
      cri: 370,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Raffles' Guitar",
    img: "img/items/weapons/rafflesguitar.png",
    stats: {
      atk: 1281,
      cri: 378,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Round bouquet",
    img: "img/items/weapons/roundbouquet.png",
    stats: {
      atk: 1004,
      agi: 452,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Samsara valhalla",
    img: "img/items/weapons/samsaravalhalla.png",
    stats: {
      atk: 1224,
      agi: 372,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Semele's bouquet",
    img: "img/items/weapons/semelesbouquet.png",
    stats: {
      atk: 1218,
      agi: 378,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Shrine maiden's dagger",
    img: "img/items/weapons/shrinemaidensdagger.jpg",
    stats: {
      atk: 1104,
      cri: 311,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Spear of divine punishment",
    img: "img/items/weapons/spearofdivinepunishment.jpg",
    stats: {
      atk: 1108,
      hp: 211,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Steel rose",
    img: "img/items/weapons/steelrose.png",
    stats: {
      atk: 991,
      cri: 424,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Talisman of slaughter",
    img: "img/items/weapons/talismanofslaughter.png",
    stats: {
      atk: 1219,
      agi: 377,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Wand",
    img: "img/items/weapons/wand.png",
    stats: {
      atk: 1015,
      agi: 395,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Water gun",
    img: "img/items/weapons/watergun.png",
    stats: {
      atk: 1121,
      cri: 469,
      version: { name: "kr", number: "2.7.6" },
    },
  },
];
