import { accessories } from "./accessories";
import { armors } from "./armors";
import { weapons } from "./weapons";

function getVersions() {
  let versions = [...accessories, ...armors, ...weapons].map((r) => r?.stats?.version.number);
  versions = versions.filter((v, index) => versions.indexOf(v) === index);
  versions.sort();
  return versions;
}

export const versions = getVersions();
