export const elements = ["dark", "fire", "light", "water", "forest"];
export const roles = ["attacker", "balancer", "defencer", "healer", "supporter"];

export const buffs = [
  "atk-def switch",
  "barrier",
  "buff steal",
  "debuff immunity",
  "def",
  "double-edge sword",
  "drive gauge",
  "drive skill dmg def",
  "ignore def",
  "overload",
  "pain adaptation",
  "pain sublimation",
  "reflect",
  "regen",
];

export enum EffectType {
  PVE = "pve",
  PVP = "pvp",
  Raid = "raid",
  WB = "world boss",
}

export enum FilterKeys {
  Element,
  Role,
  SkillType,
}

export enum Category {
  accessories = "Accessories",
  armors = "Armors",
  weapons = "Weapons",
}