import * as React from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Category } from "../../utils/constants";
// import { versions } from "../../utils/data";

interface IProps {
  filter: IFilter;
  onChange: (filter: IFilter) => void;
}

export interface IFilter {
  version?: string;
  category: Category;
}

export default function Filters(props: IProps) {
  const handleCategoryChange = (event: any) => {
    props.onChange(Object.assign({}, props.filter, { category: event.target.value }));
  };

  // const handleVersionChange = (event: any) => {
  //   props.onChange(Object.assign({}, props.filter, { version: event.target.value }));
  // };

  return (
    <>
      <Select label="Category" value={props.filter.category} onChange={handleCategoryChange}>
        {Object.entries(Category).map(([key, value]) => (
          <MenuItem key={key} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      {/* <Select
        label="Version"
        value={props.filter.version}
        onChange={handleVersionChange}
        displayEmpty
      >
        <MenuItem value={undefined}>All versions</MenuItem>
        {versions.map((v) => (
          <MenuItem key={v} value={v}>
            {v}
          </MenuItem>
        ))}
      </Select> */}
    </>
  );
}
