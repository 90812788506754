import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom"
import { config } from './utils/config';

import './index.css';
import './i18n'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.AUTH_DOMAIN}
      clientId={config.AUTH_CLIENTID}
      redirectUri={window.location.origin}
      audience={config.AUTH_TOKEN_AUDIENCE}
      scope={config.AUTH_SCOPE}
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
