import { IItem } from "../interfaces";

export const armors: IItem[] = [
  {
    name: "Dana's miter",
    img: "img/items/armors/danasmiter.png",
    stats: {
      hp: 1465,
      atk: 570,
      def: 970,
      version: { name: "kr", number: "2.9.3" },
    },
  },
  {
    name: "철벽의 힐데그림",
    img: "img/items/armors/힐데그림.png",
    stats: {
      hp: 1465,
      atk: 570,
      def: 970,
      version: { name: "kr", number: "2.9.0" },
    },
    patch: "2022-07-21",
  },

  {
    name: "Racer jacket",
    img: "img/items/armors/racerjacket.png",
    stats: {
      agi: 437,
      def: 647,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Custom designer cap",
    img: "img/items/armors/customdesignercap.png",
    stats: {
      hp: 294,
      def: 547,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Udambara's wings",
    img: "img/items/armors/udambaraswings.png",
    stats: {
      hp: 693,
      def: 391,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Dainty robe",
    img: "img/items/armors/daintyrobe.png",
    stats: {
      def: 591,
      cri: 104,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Cain's fascia",
    img: "img/items/armors/cainsfascia.png",
    stats: {
      hp: 580,
      agi: 386,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Pakhet's jumper",
    img: "img/items/armors/pakhetsjumper.png",
    stats: {
      def: 590,
      agi: 494,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "두엣샤의 스타킹",
    img: "img/items/armors/두엣샤의 스타킹.png",
    stats: {
      hp: 776,
      def: 308,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Ophio's circlet",
    img: "img/items/armors/ophioscirclet.png",
    stats: {
      hp: 325,
      def: 759,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "러블리플로피햇",
    img: "img/items/armors/러블리플로피햇.png",
    stats: {
      hp: 329,
      def: 755,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Laima's sleep dress",
    img: "img/items/armors/laimassleepdress.png",
    stats: {
      def: 731,
      agi: 336,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "완벽한코트",
    img: "img/items/armors/완벽한코트.png",
    stats: {
      hp: 705,
      def: 379,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "아우로라의날개",
    img: "img/items/armors/아우로라의날개.png",
    stats: {
      hp: 488,
      def: 596,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "필로테스의치마",
    img: "img/items/armors/필로테스의치마.png",
    stats: {
      agi: 502,
      def: 582,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "1st anniversary cap",
    img: "img/items/armors/1stanniversarycap.png",
    stats: {
      agi: 345,
      def: 382,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Artemisia's uniform",
    img: "img/items/armors/artemisiasuniform.png",
    stats: {
      def: 755,
      agi: 329,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Astronaut suit",
    img: "img/items/armors/astronautsuit.png",
    stats: {
      hp: 460,
      atk: 160,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Ballet dress",
    img: "img/items/armors/balletdress.jpg",
    stats: {
      def: 610,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Bari's scroll",
    img: "img/items/armors/barisscroll.png",
    stats: {
      agi: 748,
      def: 336,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Bikini",
    img: "img/items/armors/bikini.jpg",
    stats: {
      hp: 460,
      def: 160,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Bridal mask",
    img: "img/items/armors/bridalmask.png",
    stats: {
      agi: 160,
      def: 460,
      version: { name: "kr", number: "2.7.6" },
    },
  },

  {
    name: "Bustle dress",
    img: "img/items/armors/bustledress.png",
    stats: {
      hp: 561,
      def: 374,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Chicken hat",
    img: "img/items/armors/chickenhat.png",
    stats: {
      hp: 460,
      agi: 160,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Crown of amos",
    img: "img/items/armors/crownofamos.png",
    stats: {
      hp: 391,
      agi: 693,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Exorcist's vambraces",
    img: "img/items/armors/exorcistsvambraces.png",
    stats: {
      hp: 759,
      agi: 325,
      version: { name: "kr", number: "2.7.6" },
    },
  },

  {
    name: "Flower veil",
    img: "img/items/armors/flowerveil.png",
    stats: {
      hp: 556,
      def: 260,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Goggles of Boreas",
    img: "img/items/armors/gogglesofboreas.png",
    stats: {
      hp: 502,
      def: 582,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Gunman's swimsuit",
    img: "img/items/armors/gunmansswimsuit.png",
    stats: {
      hp: 556,
      agi: 411,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Haute dress",
    img: "img/items/armors/hautedress.png",
    stats: {
      hp: 75,
      def: 545,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Hyenang's dress",
    img: "img/items/armors/hyenangsdress.png",
    stats: {
      hp: 731,
      def: 336,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Kush's Paltoshi",
    img: "img/items/armors/kushspaltoshi.png",
    stats: {
      hp: 705,
      agi: 379,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Limos' bikini",
    img: "img/items/armors/limosbikini.png",
    stats: {
      hp: 556,
      def: 411,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Lovely cufflinks",
    img: "img/items/armors/lovelycufflinks.png",
    stats: {
      hp: 590,
      def: 494,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Moonlight purple lace",
    img: "img/items/armors/moonlightpurplelace.png",
    stats: {
      hp: 673,
      agi: 411,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Priest armor",
    img: "img/items/armors/priestarmor.png",
    stats: {
      def: 620,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Original new year's outfit",
    img: "img/items/armors/originalnewyearsoutfit.jpg",
    stats: {
      hp: 610,
      def: 320,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Santa hat",
    img: "img/items/armors/santahat.png",
    stats: {
      def: 470,
      agi: 150,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Semele's dress",
    img: "img/items/armors/semelesdress.png",
    stats: {
      hp: 748,
      def: 336,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Tailored outfit",
    img: "img/items/armors/tailoredoutfit.jpg",
    stats: {
      def: 470,
      agi: 150,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Temptation dress",
    img: "img/items/armors/temptationdress.png",
    stats: {
      def: 411,
      agi: 673,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Wedding dress",
    img: "img/items/armors/weddingdress.jpg",
    stats: {
      def: 460,
      hp: 160,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Wetsuit",
    img: "img/items/armors/wetsuit.jpg",
    stats: {
      hp: 610,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Yuna's skirt",
    img: "img/items/armors/yunasskirt.png",
    stats: {
      hp: 329,
      agi: 755,
      version: { name: "kr", number: "2.7.6" },
    },
  },
];
