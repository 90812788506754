import { IItem } from "../interfaces";

export const accessories: IItem[] = [
  {
    name: "Raffle's choker",
    img: "img/items/accessories/raffleschoker.png",
    stats: {
      hp: 564,
      atk: 436,
      version: { name: "kr", number: "2.9.3" },
    },
  },
  {
    name: "탐구용 보안경",
    img: "img/items/accessories/탐구용 보안경.png",
    stats: {
      hp: 567,
      agi: 433,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "노리개",
    img: "img/items/accessories/노리개.png",
    stats: {
      hp: 374,
      agi: 626,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "로술렌타 서클릿",
    img: "img/items/accessories/로술렌타 서클릿.png",
    stats: {
      hp: 644,
      atk: 356,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "두엣샤의완장",
    img: "img/items/accessories/두엣샤의완장.png",
    stats: {
      hp: 591,
      agi: 409,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Moonlight heart necklace",
    img: "img/items/accessories/moonlightheartnecklace.png",
    stats: {
      hp: 436,
      atk: 564,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Rusalka's ribbon",
    img: "img/items/accessories/rusalkasribbon.png",
    stats: {
      hp: 626,
      cri: 374,
      version: { name: "kr", number: "2.9.0" },
    },
  },
  {
    name: "Freedom shoes",
    img: "img/items/accessories/freedomshoes.png",
    stats: {
      hp: 374,
      def: 626,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "어둡소",
    img: "img/items/accessories/어둡소.png",
    stats: {
      atk: 567,
      agi: 433,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "Night ornament",
    img: "img/items/accessories/nightornament.png",
    stats: {
      hp: 541,
      def: 459,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "어둠의리본",
    img: "img/items/accessories/어둠의리본.png",
    stats: {
      atk: 433,
      agi: 567,
      version: { name: "kr", number: "2.8.5" },
    },
  },
  {
    name: "크레스니크의팔찌",
    img: "img/items/accessories/크레스니크의팔찌.png",
    stats: {
      hp: 409,
      agi: 591,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "싹이났소",
    img: "img/items/accessories/싹이났소.png",
    stats: {
      atk: 400,
      cri: 600,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "빛나소",
    img: "img/items/accessories/빛나소.png",
    stats: {
      hp: 433,
      atk: 567,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "화났소",
    img: "img/items/accessories/화났소.png",
    stats: {
      hp: 459,
      def: 541,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "1st anniversary glasses",
    img: "img/items/accessories/1stanniversaryglasses.png",
    stats: {
      hp: 416,
      def: 178,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "1st candy ring",
    img: "img/items/accessories/1stcandyring.png",
    stats: {
      atk: 230,
      def: 360,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "300th anniversary ring",
    img: "img/items/accessories/300thanniversaryring.png",
    stats: {
      atk: 350,
      cri: 220,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Alchemy kit",
    img: "img/items/accessories/alchemykit.png",
    stats: {
      atk: 364,
      cri: 242,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Anniversary ring #222",
    img: "img/items/accessories/anniversaryring222.png",
    stats: {
      atk: 350,
      agi: 220,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Bradamante's ribbon",
    img: "img/items/accessories/bradamantesribbon.png",
    stats: {
      atk: 567,
      cri: 433,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Bride's ring",
    img: "img/items/accessories/bridesring.png",
    stats: {
      atk: 156,
      cri: 472,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Cain's thurible",
    img: "img/items/accessories/cainsthurible.png",
    stats: {
      atk: 561,
      agi: 374,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Chang'e's hairpin",
    img: "img/items/accessories/changeshairpin.png",
    stats: {
      atk: 350,
      cri: 220,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Christmas wreath",
    img: "img/items/accessories/christmaswreath.png",
    stats: {
      agi: 360,
      cri: 230,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Cookie Mona",
    img: "img/items/accessories/cookiemona.png",
    stats: {
      hp: 257,
      def: 348,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Dana's pendant",
    img: "img/items/accessories/danaspendant.png",
    stats: {
      hp: 407,
      atk: 450,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Dark skeleton",
    img: "img/items/accessories/darkskeleton.png",
    stats: {
      hp: 526,
      cri: 459,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Eshu's goggles",
    img: "img/items/accessories/eshusgoggles.png",
    stats: {
      atk: 564,
      agi: 421,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Exorcist's headset",
    img: "img/items/accessories/exorcistsheadset.png",
    stats: {
      hp: 561,
      def: 424,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Fairy circlet",
    img: "img/items/accessories/fairycirclet.png",
    stats: {
      hp: 360,
      atk: 230,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Fresh blood choker",
    img: "img/items/accessories/freshbloodchoker.png",
    stats: {
      agi: 341,
      cri: 341,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Fugitive's tiara",
    img: "img/items/accessories/fugitivestiara.png",
    stats: {
      agi: 549,
      cri: 526,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Glass rosary",
    img: "img/items/accessories/glassrosary.png",
    stats: {
      hp: 525,
      atk: 350,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Gran prix halo",
    img: "img/items/accessories/granprixhalo.png",
    stats: {
      atk: 462,
      cri: 693,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Handheld console",
    img: "img/items/accessories/handheldconsole.png",
    stats: {
      def: 360,
      agi: 230,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Horse medallion",
    img: "img/items/accessories/horsemedallion.png",
    stats: {
      def: 230,
      cri: 360,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Hyenang's flower",
    img: "img/items/accessories/hyenangsflower.png",
    stats: {
      hp: 459,
      agi: 526,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Joystick",
    img: "img/items/accessories/joystick.png",
    stats: {
      atk: 590,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Kush's Badge",
    img: "img/items/accessories/kushsbadge.png",
    stats: {
      hp: 567,
      cri: 433,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Limo's bib",
    img: "img/items/accessories/limosbib.png",
    stats: {
      atk: 561,
      cri: 374,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Lovely hair band",
    img: "img/items/accessories/lovelyhairband.png",
    stats: {
      hp: 459,
      cri: 541,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Maid collar",
    img: "img/items/accessories/maidcollar.png",
    stats: {
      agi: 360,
      cri: 230,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Moon earrings",
    img: "img/items/accessories/moonearrings.png",
    stats: {
      atk: 357,
      def: 238,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Motorcycle helmet",
    img: "img/items/accessories/motorcyclehelmet.png",
    stats: {
      hp: 245,
      atk: 345,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Neptune's headband",
    img: "img/items/accessories/neptunesheadband.png",
    stats: {
      hp: 436,
      agi: 564,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "New year's pouch",
    img: "img/items/accessories/newyearspouch.png",
    stats: {
      hp: 416,
      agi: 240,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Nirti's orb",
    img: "img/items/accessories/nirtisorb.png",
    stats: {
      atk: 500,
      agi: 500,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Pakhet's cat ears",
    img: "img/items/accessories/pakhetscatears.png",
    stats: {
      hp: 564,
      agi: 436,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Pyramid totem",
    img: "img/items/accessories/pyramidtotem.png",
    stats: {
      hp: 330,
      cri: 275,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Old general's skullcap",
    img: "img/items/accessories/oldgeneralsskullcap.png",
    stats: {
      atk: 471,
      cri: 386,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Ophio's ribbon",
    img: "img/items/accessories/ophiosribbon.png",
    stats: {
      atk: 564,
      cri: 421,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Rage patch",
    img: "img/items/accessories/ragepatch.png",
    stats: {
      def: 148,
      cri: 446,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Ribbon headband",
    img: "img/items/accessories/ribbonheadband.png",
    stats: {
      hp: 397,
      atk: 538,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Rosary of slaughter",
    img: "img/items/accessories/rosaryofslaughter.png",
    stats: {
      hp: 564,
      def: 436,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Running competition trophy",
    img: "img/items/accessories/runningcompetitiontrophy.png",
    stats: {
      atk: 409,
      cri: 591,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Sailor cap",
    img: "img/items/accessories/sailorcap.png",
    stats: {
      def: 538,
      agi: 397,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Seasonal hair ribbon",
    img: "img/items/accessories/seasonalhairribbon.png",
    stats: {
      atk: 573,
      cri: 427,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Semele's veil",
    img: "img/items/accessories/semelesveil.png",
    stats: {
      atk: 459,
      agi: 541,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Solar music box",
    img: "img/items/accessories/solarmusicbox.png",
    stats: {
      atk: 360,
      agi: 230,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Temptation tiara",
    img: "img/items/accessories/temptationtiara.png",
    stats: {
      hp: 626,
      agi: 374,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Vampire exorcism kit",
    img: "img/items/accessories/vampireexorcismkit.png",
    stats: {
      atk: 345,
      def: 197,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Victory hat",
    img: "img/items/accessories/victoryhat.png",
    stats: {
      hp: 567,
      atk: 433,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Young totem",
    img: "img/items/accessories/youngtotem.png",
    stats: {
      hp: 340,
      agi: 220,
      version: { name: "kr", number: "2.7.6" },
    },
  },
  {
    name: "Zephyros' sunglasses",
    img: "img/items/accessories/zephyrossunglasses.png",
    stats: {
      hp: 500,
      agi: 500,
      version: { name: "kr", number: "2.9.3" },
    },
    // {
    //   hp: 626,
    //   agi: 374,
    //   version: { name: "kr", number: "2.7.6" },
    // }
  },
];
