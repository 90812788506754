import React, { useEffect, useState } from "react";

import { accessories, armors, weapons } from "../../utils/data";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import { IItem } from "../../utils/interfaces";
import { Category } from "../../utils/constants";
import Filters, { IFilter } from "./Filters";

const columns: GridColDef[] = [
  {
    field: "img",
    headerName: "-",
    description: "Image",
    sortable: false,
    renderCell: (params: any) => (
      <img
        src={params.getValue(params.id, "img")}
        style={{ height: "50px" }}
        alt={params.getValue(params.id, "name")}
      />
    ),
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  {
    field: "atk",
    headerName: "ATK",
    width: 120,
    type: "number",
  },
  {
    field: "agi",
    headerName: "AGI",
    width: 120,
    type: "number",
  },
  {
    field: "cri",
    headerName: "CRI",
    width: 120,
    type: "number",
  },
  {
    field: "def",
    headerName: "DEF",
    width: 120,
    type: "number",
  },
  {
    field: "hp",
    headerName: "HP",
    width: 120,
    type: "number",
  },
  {
    field: "total",
    headerName: "Total",
    width: 120,
    type: "number",
  },
];

const createData = (rawRow: IItem, index: number) => {
  let total = 0;
  if (rawRow.stats !== undefined) {
    Object.keys(rawRow.stats)
      .filter((k) => k !== "version")
      .forEach((k: string) => {
        //@ts-ignore
        if (rawRow.stats[k] !== undefined) total += parseInt(rawRow.stats[k]);
      });
  }
  return {
    id: index,
    img: rawRow.img,
    name: rawRow.name,
    atk: rawRow.stats?.atk,
    agi: rawRow.stats?.agi,
    cri: rawRow.stats?.cri,
    def: rawRow.stats?.def,
    hp: rawRow.stats?.hp,
    total,
  };
};

export default function Items() {
  const [rows, setRows] = useState([] as any[]);
  // const [version, setVersion] = useState(undefined as string | undefined);
  const [category, setCategory] = useState(Category.accessories);

  useEffect(() => {
    let rows: any[] = [];
    switch (category) {
      case Category.accessories:
        rows = accessories;
        break;
      case Category.armors:
        rows = armors;
        break;
      case Category.weapons:
        rows = weapons;
        break;
    }
    // if (version !== undefined) {
    //   rows = rows.filter((row) => row?.stats?.version.number === version);
    // }

    rows = rows.map((row, index) => createData(row, index));
    rows.sort((a, b) => (a.total > b.total ? -1 : 1));
    setRows(rows);
  }, [
    category,
    // , version
  ]);

  return (
    <>
      <Filters
        filter={{
          category,
          // version
        }}
        onChange={(filter: IFilter) => {
          setCategory(filter.category);
          // setVersion(filter.version);
        }}
      />
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          // pageSize={5}
          // checkboxSelection
          disableSelectionOnClick
        />
      </div>
    </>
  );
}
